<template>
  <teleport to="#portal-target">
    <div class="welcome-modal">
      <div class="modal-box">
        <div class="content-box">
          <p class="alert-title">ALERT NOTIFICATION</p>
          <section class="content-section">
            <div class="grids">
              <div :key="index" v-for="(grids, index) in gridsArray" />
              <img src="@/images/frame.png" />
            </div>
            <div class="main-content">
              <div class="outer-links">
                <div class="link-row">
                  <a target="_blank" href="#" class="unknown">
                    <img
                      class="hover"
                      src="@/images/social-links/unknown.png"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCIguLRHHXCDClavBIv1jkag"
                    class="youtube"
                  >
                    <button class="platform-button">
                      <div class="icon">
                        <img
                          class="default"
                          src="@/images/social-links/ytb.png"
                        />
                        <img
                          class="hover"
                          src="@/images/social-links/ytbHover.png"
                        />
                      </div>
                      <span class="platform-name">Youtube</span>
                    </button>
                  </a>
                </div>
                <div class="link-row">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/NTUSTARCH.ThsisDesign"
                    class="facebook"
                  >
                    <button class="platform-button">
                      <div class="icon">
                        <img
                          class="default"
                          src="@/images/social-links/facebook.png"
                        />
                        <img
                          class="hover"
                          src="@/images/social-links/facebookhover.png"
                        />
                      </div>
                      <span class="platform-name">Facebook</span>
                    </button>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/2021_ntustarch/"
                    class="instagram"
                  >
                    <button class="platform-button">
                      <div class="icon">
                        <img
                          class="default"
                          src="@/images/social-links/insta.png"
                        />
                        <img
                          class="hover"
                          src="@/images/social-links/instahover.png"
                        />
                      </div>
                      <span class="platform-name">Instagram</span>
                    </button>
                  </a>
                </div>
              </div>
              <div class="description">
                <p>{{ `疫情期間\n原定 2021.06.11 - 06.14\n松菸展覽取消！` }}</p>
                <p>
                  {{
                    `改為線上系列展覽\n關注 IG: 2021_NTUSTARCH\n獲得即時資訊`
                  }}
                </p>
                <img src="@/images/round.png" alt="rotate image" />
              </div>
            </div>
          </section>
          <button @click="onClose" class="confirm-button" type="button">
            ＯＫ
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import SocialLinkButton from "@/components/atoms/SocialLinkButton";
export default {
  name: "Welcome Alert",
  components: { SocialLinkButton },
  props: {
    isPopModal: Boolean,
    onClose: Function,
  },
  setup(props) {
    const { isPopModal, onClose } = props;
    const gridAmount = computed(() => {
      return window.innerWidth > 576 ? 42 : 36;
    });
    const gridsArray = Array.from(Array(gridAmount.value).keys());
    const app = document.querySelector("#app");

    onMounted(() => {
      if (isPopModal) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
        app.style.filter = "blur(2px)";
      }
    });

    onUnmounted(() => {
      document.body.style.height = "unset";
      document.body.style.overflow = "auto";
      app.style.filter = "unset";
    });

    return {
      onClose,
      gridsArray,
    };
  },
};
</script>

<style lang="scss" scoped>
$border-width: 2px;

.welcome-modal {
  @include modal;

  .modal-box {
    background-color: $black;
  }
}

.content-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.6);
  position: relative;
  border: $border-width solid $white;
  padding-bottom: 50px;

  @include RWD($tablet) {
    width: 350px;
  }

  .alert-title {
    border-bottom: $border-width solid $white;
    font-family: "Red Rose";
    color: $white;
    margin: 0;
    box-shadow: inset 0 2px 10px rgba(255, 255, 255, 0.6);
    font-size: 42px;
    letter-spacing: 3px;
    padding: 23px;
    line-height: 1;

    @include RWD($mediumLaptop) {
      font-size: 33px;
    }

    @include RWD($smallLaptop) {
      font-size: 33px;
    }

    @include RWD($tablet) {
      font-size: 24px;
      text-align: center;
      letter-spacing: 2px;
      padding: 12px;
    }
  }

  .content-section {
    position: relative;
    width: 100%;
    padding-bottom: calc((100% * 6) / 7);
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.6);

    @include RWD($tablet) {
      padding-bottom: 100%;
    }

    .grids {
      position: absolute;
      width: 200%;
      height: 200%;
      transform-origin: top left;
      transform: scale(0.5);
      display: flex;
      flex-wrap: wrap;

      & > div {
        flex: 0 0 calc(100% / 7);
        border: 1px solid $white;
        opacity: 0.2;

        @include RWD($tablet) {
          flex: 0 0 calc(100% / 6);
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100% / 7 * 4;
      }
    }

    .main-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .description {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 430px;
        height: 430px;
        border-radius: 50%;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: 100% 100%;

        border-radius: 50%;
        border: 3px solid $white;

        @include RWD($mediumLaptop) {
          width: 330px;
          height: 330px;
        }

        @include RWD($smallLaptop) {
          width: 330px;
          height: 330px;
        }

        @include RWD($tablet) {
          width: 305px;
          height: 305px;
        }

        p {
          color: $white;
          font-size: 20px;
          line-height: 30px;
          font-family: "Zpix";
          text-align: center;
          white-space: pre-line;
          margin: 0;

          @include RWD($mediumLaptop) {
            font-size: 14px;
            line-height: 26px;
          }

          @include RWD($smallLaptop) {
            font-size: 14px;
            line-height: 26px;
          }

          @include RWD($tablet) {
            font-size: 16px;
            line-height: 30px;
          }

          &:first-child {
            padding: 20px 0 15px;
          }

          &:nth-child(2) {
            padding: 15px 0 20px;
            background-image: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.4),
              rgba(255, 255, 255, 0)
            );
          }
        }

        img {
          position: absolute;
          width: 102%;
          height: 102%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          //   animation: rotate-animation 20s infinite;
        }
      }

      .outer-links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .link-row {
          display: flex;
          justify-content: space-between;

          a {
            flex: 0 0 calc(100% / 7);
            padding-bottom: calc(100% / 7);
            height: 0;
            box-sizing: border-box;
            text-decoration: none;
            font-size: 0;
            box-shadow: 0 0 5px rgba(255, 255, 255, 0.6),
              inset 0 0 5px rgba(255, 255, 255, 0.6);
            position: relative;

            @include RWD($tablet) {
              flex: 0 0 calc(100% / 6);
              padding-bottom: calc(100% / 6);
            }

            &.unknown {
              border-right: 1px solid $white;
              border-bottom: 1px solid $white;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 53px;
              }
            }

            &.youtube {
              border-left: 1px solid $white;
              border-bottom: 1px solid $white;
            }

            &.facebook {
              border-top: 1px solid $white;
              border-right: 1px solid $white;
            }
            &.instagram {
              border-top: 1px solid $white;
              border-left: 1px solid $white;
            }

            &.youtube,
            &.facebook,
            &.instagram {
              &:hover {
                background-color: $neon;
              }
            }

            & > button {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            & > img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .confirm-button {
    position: absolute;
    width: calc(100% + 4px);
    left: -2px;
    bottom: -2px;
    color: $white;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: "Zpix";
    padding: 10px 0;
    height: 52px;
    background-color: $black;
    border: $border-width solid $white;
    line-height: 1;

    &:hover {
      color: $neon;
      border-color: $neon;
    }

    @include RWD($mediumLaptop) {
      font-size: 28px;
    }
  }
}

@keyframes rotate-animation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.platform-button {
  font-size: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 35px;
    height: 35px;

    &.hover {
      display: none;
    }

    @include RWD($tablet) {
      width: 28px;
      height: 28px;
    }
  }

  span {
    font-family: "Zpix";
    font-size: 12px;
    line-height: 12px;
    color: $white;
    margin-top: 10px;

    @include RWD($mediumLaptop) {
      transform: scale(0.8);
      margin-top: 5px;
    }

    @include RWD($smallLaptop) {
      transform: scale(0.8);
      margin-top: 5px;
    }

    @include RWD($tablet) {
      transform: scale(0.7);
      margin-top: 5px;
    }
  }

  &:hover {
    background-color: $neon;
    .icon {
      img {
        &.default {
          display: none;
        }

        &.hover {
          display: inline;
        }
      }
    }

    span {
      color: $black;
    }
  }
}
</style>
