<template>
  <div class="player">
    <span v-if="media === 'desktop'">MUSIC</span>
    <div v-if="media === 'desktop'" class="buttons">
      <button @click="pauseMusic">
        <span class="pause">
          <span />
          <span />
        </span>
      </button>
      <button @click="playMusic">
        <span class="play" />
      </button>
      <button @click="stopMusic">
        <span class="stop" />
      </button>
    </div>
    <div v-else class="buttons mobile">
      <button v-if="musicIsPlaying" @click="pauseMusic">
        <span class="pause">
          <span />
          <span />
        </span>
      </button>
      <button v-else @click="playMusic">
        <span class="play" />
      </button>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, onBeforeUnmount, ref } from "vue";
import Music from "../../../assets/bg-music.mp3";
export default {
  name: "MusicPlayer",
  setup() {
    const music = ref(new Audio(Music));
    const musicIsPlaying = ref(false);

    const media = inject("media");

    onMounted(() => {
      playMusic();
    });

    onBeforeUnmount(() => {
      stopMusic();
    });

    const playMusic = () => {
      music.value.play();
      musicIsPlaying.value = true;
    };

    const pauseMusic = () => {
      music.value.pause();
      musicIsPlaying.value = false;
    };

    const stopMusic = () => {
      music.value.pause();
      music.value.currentTime = 0;
    };

    return {
      music,
      media,
      playMusic,
      pauseMusic,
      stopMusic,
      musicIsPlaying,
    };
  },
};
</script>

<style lang="scss" scoped>
.player {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 3;

  @include RWD($tablet) {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: #000000b2;
  }

  & > span {
    color: $white;
    font-family: "Zpix";
    margin-right: 10px;
    letter-spacing: 2px;
  }

  & > .buttons {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid $white;
    font-size: 0;

    @include RWD($tablet) {
      padding: 6px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;

      & + button {
        margin-left: 10px;
      }
    }
  }
}

.pause {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;

  span {
    width: 5px;
    background-color: $white;
    height: 90%;

    &:first-child {
      margin-right: 3px;
    }
  }
}

.play {
  display: inline-block;
  width: 10px;
  height: 100%;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  background-color: $white;
}

.stop {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: $white;
}
</style>
