<template>
  <div class="home-page">
    <Header v-if="showHeader" />
    <div class="banner" ref="bannerRef">
      <div class="main-info">
        <h2>台灣科技大學建築系 2021 線上畢業展</h2>
        <h3 v-if="media === 'desktop'">
          NTUST ARCHITECTURE<br />GRADUATION SHOW 2021
        </h3>
        <h3 v-else>NTUST ARCHITECTURE<br />GRADUATION SHOW<br />2021</h3>
        <div class="cta-buttons">
          <router-link to="/portfolios">
            <button class="action-button">PORTFOLIO</button>
          </router-link>
          <router-link to="/3d-tour">
            <button class="action-button">3D TOUR</button>
          </router-link>
        </div>
      </div>
      <video
        class="video"
        preload="auto"
        src="@/assets/key-video.mp4"
        type="video/mp4"
        autoplay
        muted
        loop
      />
      <MusicPlayer />
    </div>
    <div class="detail-info">
      <GridBackground v-if="showHeader"></GridBackground>
      <!--      <img-->
      <!--        v-if="showHeader"-->
      <!--        class="grid"-->
      <!--        src="@/images/backgrounds/grids/top-dark.png"-->
      <!--        alt="decoration"-->
      <!--      />-->
      <section ref="introRef" class="intro">
        <h4 class="section-title">{{ `ABOUT US\n& THE SHOW` }}</h4>
        <div class="content">
          <img
            src="@/images/return0logo.png"
            width="428"
            alt="show name: return 0"
          />
          <p>
            {{
              `2021年台科建築畢業設計實體展因新冠疫情取消，卻也為我們帶來線上作品典藏的開端。「RETURN 0;」既代表了學生生涯的一段結束，也隱含著下一段即將開始的未來。\n
            「RETURN 0;」為電腦程式C語言執行完一段函數後，回傳「任務成功」的訊息；英文詞意上同時隱含著「返回起點」的意味。一詞完美詮釋了，以數位輔助設計聞名的台科建築系畢業生的始與末。`
            }}
          </p>
        </div>
      </section>
      <div class="backgrounds">
        <img class="circle" src="@/images/backgrounds/circle.png" />
        <img class="line" src="@/images/backgrounds/line.png" />
      </div>
      <section ref="aboutRef" class="about">
        <h4 class="section-title">{{ `ABOUT NTUST\nARCHITECTURE` }}</h4>
        <div class="content">
          <p>
            {{
              `本所成立於民國80年，於台灣工業技術學院時代起，在工學院下以建築學程先從研究所開始發展，當時名為「工程技術研究所之設計技術學程建築組」，85年改為「工程技術研究所之建築學程」，致力於師資人力及設備資源之充實。\n
            以實現最適合台灣社會的現代化建築教育為目標，規劃多元化且可相互轉換的管道與機制，整合實務能力與學術專業知識兩方面的訓練，讓學生體會到建築與社會整體環境的互動關係，進而引發對各相關專業的學習動機，發展出多樣化、實務性及具競爭力的教育特色，追求技職教育的卓越與創新，配合國內科技產業需求與未來發展目標，培育具多元技能且優質的技職人才，確保產業穩定發展，提升我國科技在國際上的競爭力。`
            }}
          </p>
        </div>
      </section>
      <section ref="classRef" class="class">
        <div class="intro-box">
          <p>CLASS OF 2021</p>
          <article v-if="media === 'desktop'">
            <p>
              臺科建築正面看待疫情所帶來的大環境改變，面對種種挑戰，<br />視其為改變的契機，擴大為一場線上慶典。
            </p>
            <p>
              今起一連數周，一系列的活動包含：線上互動、各式抽獎、專業講座、線上論壇與Podcast。我們將在FB與IG持續更新詳情，在網路世界中，不間斷的持續向您分享臺科建築系的特色，用有趣的內容陪伴各位度過疫情的難關。除此之外，今年松菸學園祭七校聯合舉辦線上活動，我們攜手「北科」、「淡江」、「中原」、「中國科大」、「銘傳」、「聯合」等六校建築系，共同推出節目。
            </p>
            <p>
              {{
                `這些精彩的內容都在六月陸續出，請大家記得持續追蹤不要錯過！\n2021 疫情之下，我們線上再見！`
              }}
            </p>
          </article>
          <article v-else>
            <p>
              臺科建築正面看待疫情所帶來的大環境改變，面對種種挑戰，視其為改變的契機，擴大為一場線上慶典。
            </p>
            <p>
              今起一連數周，一系列的活動包含：線上互動、各式抽獎、專業講座、線上論壇與Podcast。我們將在FB與IG持續更新詳情，在網路世界中，不間斷的持續向您分享臺科建築系的特色，用有趣的內容陪伴各位度過疫情的難關。除此之外，今年松菸學園祭七校聯合舉辦線上活動，我們攜手「北科」、「淡江」、「中原」、「中國科大」、「銘傳」、「聯合」等六校建築系，共同推出節目。
            </p>
            <p>
              這些精彩的內容都在六月陸續出，請大家記得持續追蹤不要錯過！2021
              疫情之下，我們線上再見！
            </p>
          </article>
        </div>
        <router-link to="/portfolios">
          <button class="action-button">VIEW ALL STUDENT WORK</button>
        </router-link>
      </section>
      <section ref="studentRef" class="students" v-if="students.length">
        <InfiniteProfileCarousel
          v-if="media === 'desktop'"
          :profileList="students"
        />
        <MobileProfileSwiper
          v-else
          :profileList="students"
          namePosition="inside"
          :startFrom="'middle'"
        />
      </section>
    </div>
  </div>
  <ModalWelcome
    v-if="isPopModal"
    :isPopModal="isPopModal"
    :onClose="closeModal"
  />
  <CurrentSectionHint :currentArea="currentArea" />
</template>

<script>
import { ref, onMounted, onUnmounted, computed, inject } from "vue";
import { mapGetters, useStore } from "vuex";
import Header from "@/components/organisms/AppHeader";
import ModalWelcome from "@/components/molecules/ModalWelcome";
import CurrentSectionHint from "@/components/molecules/CurrentSectionHint";
import MusicPlayer from "@/components/molecules/MusicPlayer";
import InfiniteProfileCarousel from "@/components/molecules/InfiniteProfileCarousel";
import MobileProfileSwiper from "@/components/molecules/MobileProfileSwiper";
import GridBackground from "@/components/grid-background/GridBackground";

export default {
  name: "Home",
  components: {
    ModalWelcome,
    InfiniteProfileCarousel,
    CurrentSectionHint,
    MusicPlayer,
    Header,
    MobileProfileSwiper,
    GridBackground,
  },
  computed: {
    ...mapGetters(["students"]),
  },
  setup() {
    const store = useStore()
    const isPopModal = ref(localStorage["is-pop-modal"] !== "false");

    const media = inject("media");
    const bannerRef = ref(null);
    const aboutRef = ref(null);
    const introRef = ref(null);
    const classRef = ref(null);
    const studentRef = ref(null);

    const calculateElementEnterPoint = (el) => {
      if (!el) {
        return;
      }

      return el.offsetTop + el.offsetHeight / 2;
    };

    const scrollDistance = ref(0);

    const currentArea = computed(() => {
      const introPosition = calculateElementEnterPoint(introRef.value);
      const aboutPosition = calculateElementEnterPoint(aboutRef.value);
      const classPosition = calculateElementEnterPoint(classRef.value);
      const studentPosition = calculateElementEnterPoint(studentRef.value);

      if (scrollDistance.value >= studentPosition) {
        return 4;
      }

      if (scrollDistance.value >= classPosition) {
        return 3;
      }

      if (scrollDistance.value >= aboutPosition) {
        return 2;
      }

      if (scrollDistance.value >= introPosition) {
        return 1;
      }

      return 0;
    });

    const showHeader = computed(() => {
      if (!bannerRef.value) return false;
      const isPositionToShow =
        scrollDistance.value > bannerRef.value.offsetHeight;

      const isMenuOpen = document.querySelector(".guide-modal")
        ? document.querySelector(".guide-modal").style.display !== "none"
        : false;

      return isPositionToShow || isMenuOpen;
    });

    const handleScroll = () => {
      scrollDistance.value = window.scrollY;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const closeModal = () => {
      isPopModal.value = false;
      localStorage["is-pop-modal"] = false;
    };

    return {
      bannerRef,
      isPopModal,
      closeModal,
      aboutRef,
      introRef,
      classRef,
      studentRef,
      currentArea,
      media,
      showHeader,
    };
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  color: $white;
}

.banner {
  display: flex;
  height: 100vh;
  background-color: $black;
  position: relative;
  overflow: hidden;

  .main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    color: $white;
    z-index: 1;

    h2 {
      font-family: "Zpix";
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 2px;
      margin: 0 0 40px;

      @include RWD($mediumLaptop) {
        font-size: 14px;
        margin: 0 0 28px;
      }

      @include RWD($tablet) {
        font-size: 14px;
        margin: 0 0 20px;
      }
    }

    h3 {
      font-family: "Red Rose";
      font-size: 80px;
      line-height: 105px;
      letter-spacing: 4px;
      margin: 0;
      text-align: center;

      @include RWD($mediumLaptop) {
        font-size: 70px;
      }

      @include RWD($smallLaptop) {
        font-size: 70px;
      }

      @include RWD($tablet) {
        font-size: 26px;
        line-height: 32px;
        letter-spacing: 1px;
      }
    }

    .cta-buttons {
      margin-top: 75px;

      a {
        text-decoration: none;

        & + a {
          margin-left: 50px;
        }

        @include RWD($tablet) {
          display: block;
          & + a {
            margin-top: 25px;
            margin-left: 0;
          }
        }
      }

      button {
        font-family: "Zpix";
        padding: 12px 60px;
        font-size: 18px;
        color: $white;

        @include RWD($mediumLaptop) {
          padding: 8px 40px;
          font-size: 12px;
        }

        @include RWD($tablet) {
          width: 100%;
          display: block;
          font-size: 16px;
          padding: 18px 45px;
        }
      }
    }
  }

  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
}

.detail-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;

  .grid {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  & > section {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 2;

    & > .section-title {
      flex: 0 0 40%;
      font-family: "Red Rose";
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      color: $white;
      white-space: pre-line;
      margin-right: auto;

      @include RWD($mediumLaptop) {
        font-size: 34px;
      }

      @include RWD($tablet) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
      }
    }

    & > .content {
      flex: 0 0 50%;

      p {
        font-family: "Zpix";
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
        color: white;
        letter-spacing: 0.1em;
        text-align: justify;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          line-height: 34px;
        }

        @include RWD($smallLaptop) {
          font-size: 14px;
          line-height: 34px;
        }

        @include RWD($tablet) {
          font-size: 14px;
          line-height: 30px;
          white-space: pre-line;
        }
      }
    }
  }
}

.intro {
  padding-top: 360px;
  margin: 0 12% 0 20%;

  @include RWD($tablet) {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 50px;
    margin: 0;
  }

  .content {
    padding-top: 140px;
    white-space: pre-line;

    @include RWD($tablet) {
      padding-top: 60px;
      padding-left: 40px;
    }

    img {
      margin-bottom: 34px;

      @include RWD($mediumLaptop) {
        width: 320px;
      }

      @include RWD($tablet) {
        width: 230px;
      }
    }
  }
}

.about {
  margin: -350px 12% 0 20%;

  @include RWD($mediumLaptop) {
    margin: -260px 12% 0 20%;
  }

  @include RWD($tablet) {
    display: flex;
    flex-direction: column;
    margin: -150px 0 0;
  }

  .content {
    padding: 60px 0 0 0;
    white-space: pre-line;

    @include RWD($tablet) {
      padding: 0 0 0 40px;
    }
  }
}

.backgrounds {
  position: relative;
  top: -30px;
  align-self: flex-start;

  .circle {
    width: calc(100vw * (921 / 1813));

    @include RWD($tablet) {
      width: 230px;
    }
  }

  .line {
    position: absolute;
    left: 45%;
    top: 85%;
    width: calc(100vw * 7 / 1813);

    @include RWD($tablet) {
      top: 95%;
      left: 20px;
      width: 4px;
    }
  }
}

.class {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 500px;

  @include RWD($mediumLaptop) {
    padding-top: 200px;
  }

  @include RWD($tablet) {
    padding-top: 100px;
  }

  .intro-box {
    width: 1085px;
    height: 685px;
    padding: 0 108px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("../../images/classof2021bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include RWD($mediumLaptop) {
      width: 814px;
      height: 513px;
      padding: 0 67px;
    }

    @include RWD($tablet) {
      width: 100%;
      height: unset;
      padding: 40px 55px 23px;
      background-image: url("../../images/classof2021bg.png");
      background-size: 100% 100%;
    }

    @include RWD($phone) {
      padding: 40px 30px 23px;
    }

    & > p {
      text-align: center;
      font-family: "Red Rose";
      font-size: 80px;
      line-height: 105px;

      @include RWD($mediumLaptop) {
        font-size: 74px;
        line-height: 90px;
      }

      @include RWD($tablet) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    & > article {
      & > p {
        font-family: "Zpix";
        font-size: 20px;
        text-align: center;
        line-height: 35px;
        margin-bottom: 45px;
        white-space: pre-line;
        letter-spacing: 0.1em;

        @include RWD($mediumLaptop) {
          font-size: 14px;
          line-height: 30px;
          margin-bottom: 25px;
        }

        @include RWD($tablet) {
          font-size: 14px;
          line-height: 30px;
          text-align: justify;
        }
      }
    }
  }

  a {
    margin-top: 30px;

    button {
      padding: 12px 30px;
    }
  }
}

section.students {
  padding: 300px 0 40px;
  width: 80%;
  margin-left: 20%;

  @include RWD($tablet) {
    padding: 100px 0 0;
    margin-left: 0;
    width: 100%;
  }
}

.action-button {
  border: 1.5px solid $white;
  padding: 12px 15px;

  color: $white;
  font-size: 18px;
  line-height: 18px;
  font-family: "Zpix";
  letter-spacing: 2px;
  text-decoration: none;

  &:hover {
    background-color: $neon;
    border-color: $neon;
    color: $black;
  }

  @include RWD($mediumLaptop) {
    font-size: 12px;
  }

  @include RWD($tablet) {
    font-size: 16px;
    line-height: 16px;
    padding: 20px 30px;
    letter-spacing: 1px;
  }
}
</style>
