<template>
  <div ref="sliderElement" class="profile-slider">
    <div class="carousel-wrapper">
      <section
        class="item-cards"
        :class="{ 'no-trigger': !trigger }"
        :style="{
          transform: `translateX(${-transformXDistance}px)`,
        }"
      >
        <StudentProfileCard
          :style="{ flex: '0 0 25%' }"
          v-for="item in renderList"
          namePosition="inside"
          color="white"
          :key="item.name"
          :profileData="item"
          :scale="scale"
        />
      </section>
      <span class="shadows left" />
      <span class="shadows right" />
    </div>
    <section class="indicators" :style="indicatorsStyle">
      <button class="previous" @click="toPrevious">
        <img src="@/images/icons/buttonLeftWhite.svg" />
      </button>
      <button class="next" @click="toNext">
        <img src="@/images/icons/buttonRightWhite.svg" />
      </button>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import StudentProfileCard from "@/components/atoms/StudentProfileCard";

export default {
  name: "InfiniteProfileCarousel",
  components: { StudentProfileCard },
  props: {
    profileList: Array,
    scale: Boolean,
  },
  setup(props) {
    const { profileList = [], scale = false } = props;

    const sliderElement = ref(null);
    const slideDistance = ref(0);
    const startIndex = ref(0);
    const trigger = ref(null);
    const margin = 0.06;
    const cardWidth = 0.25;

    const getIndex = (index, arrayLength) => {
      if (index < 0) {
        return arrayLength + index;
      }
      if (index > arrayLength - 1) {
        return index - arrayLength;
      }

      return index;
    };

    const renderList = computed(() => {
      let result = [];

      for (let i = -3; i < 7; i++) {
        const index = getIndex(startIndex.value + i, profileList.length);
        result[i + 3] = profileList[getIndex(index)];
      }

      return result;
    });

    onMounted(() => {
      slideDistance.value = sliderElement.value.offsetWidth * 0.31;
      window.addEventListener("resize", handleResize);
    });

    const transformXDistance = computed(() => {
      let move = 3;

      if (trigger.value === "right") {
        move = 6;
      } else if (trigger.value === "left") {
        move = 0;
      }

      return slideDistance.value * move;
    });

    const indicatorsStyle = computed(() => {
      const distance = (1 - cardWidth * 3 - margin * (3 - 1)) * 100;

      return { marginRight: `${distance}%` };
    });

    const handleResize = () => {
      slideDistance.value = sliderElement.value.offsetWidth * 0.31;
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const toNext = () => {
      trigger.value = "right";

      let timeout = setTimeout(() => {
        trigger.value = null;
        startIndex.value = getIndex(startIndex.value + 3, profileList.length);

        clearTimeout(timeout);
      }, 400);
    };

    const toPrevious = () => {
      trigger.value = "left";

      let timeout = setTimeout(() => {
        startIndex.value = getIndex(startIndex.value - 3, profileList.length);
        trigger.value = null;

        clearTimeout(timeout);
      }, 400);
    };

    return {
      trigger,
      sliderElement,
      renderList,
      profileList,
      toPrevious,
      toNext,
      scale,
      indicatorsStyle,
      transformXDistance,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-slider {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  position: relative;

  .carousel-wrapper {
    position: relative;

    .item-cards {
      display: flex;
      flex-wrap: nowrap;
      transition: 0.4s transform linear;

      &.no-trigger {
        transition: none;
      }

      & > div {
        margin-right: 6%;
      }
    }

    .shadows {
      position: absolute;
      height: 100%;
      top: 0;

      &.left {
        left: 0;
        width: 120px;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
      }

      &.right {
        right: 0;
        width: 70px;
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
      }
    }
  }

  .indicators {
    display: flex;
    margin-top: 50px;
    margin-bottom: 0;
    margin-left: auto;

    & > button {
      width: 50px;
      height: 50px;
      border: 1px solid $white;
      padding: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        border-left: none;
      }

      & > img {
        width: 100%;
      }

      @include RWD($mediumLaptop) {
        width: 40px;
        height: 40px;
        padding: 9px;
      }
    }
  }
}
</style>
