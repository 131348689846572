<template>
  <div class="hint">
    <div class="item" :class="{ active: props.currentArea === 1 }">
      <span class="icon" />
      <span class="text">展覽介紹</span>
    </div>
    <div class="item" :class="{ active: props.currentArea === 2 }">
      <span class="icon" />
      <span class="text">系所介紹</span>
    </div>
    <div class="item" :class="{ active: props.currentArea === 3 }">
      <span class="icon" />
      <span class="text">展覽引言</span>
    </div>
    <div class="item" :class="{ active: props.currentArea === 4 }">
      <span class="icon" />
      <span class="text">本屆學生</span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "CurrentSectionHint",
  props: {
    currentArea: Number,
  },
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style lang="scss" scoped>
.hint {
  position: fixed;
  width: 100px;
  height: 100px;
  top: 50vh;
  transform: translateY(-50%);
  left: 50px;

  @include RWD($tablet) {
    display: none;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .icon {
      width: 2px;
      height: 15px;
      margin-right: 20px;
      background-color: $white;
      opacity: 0.3;
      transition: 0.2s all;
    }

    .text {
      display: none;
      color: $white;
      font-size: 16px;
      font-family: "Zpix";

      @include RWD($mediumLaptop) {
        font-size: 12px;
      }
    }
  }

  .item.active {
    .icon {
      height: 60px;
      opacity: 1;

      @include RWD($mediumLaptop) {
        height: 45px;
      }
    }

    .text {
      display: block;
    }
  }
}
</style>