<template>
  <button class="platform-button">
    <div class="icon">
      <img class="default" :src="defaultImage" />
      <img class="hover" :src="hoverImage" />
    </div>
    <span class="platform-name">{{ platform }}</span>
  </button>
</template>

<script>
export default {
  name: "SocialLinkButton",
  props: {
    platform: String,
    hoverImage: String,
    defaultImage: String,
  },
  setup(props) {
    const { platform, hoverImage, defaultImage } = props;

    return {
      platform,
      hoverImage,
      defaultImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.platform-button {
  img {
    &.hover {
      opacity: 0;
    }
  }

  &:hover {
    .icon {
      img {
        &.default {
          opacity: 0;
        }

        &.hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
