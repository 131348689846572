<template>
  <div
    @click="toStudentPage"
    class="profile-slide-card"
    :style="{ marginLeft: `${marginLeft * 100}%` }"
  >
    <div
      @mouseenter="showMask = true"
      @mouseleave="showMask = false"
      class="image-wrapper"
    >
      <img :src="profileData.coverImage" :alt="profileData.name" />
      <div class="mask" :class="{ 'is-active': showMask }" />
    </div>
    <div
      class="name-field"
      :class="{
        inside: namePosition === 'inside',
        white: color === 'white',
        scale: scale,
      }"
    >
      <p>{{ profileData.name_en }}</p>
      <p>{{ profileData.name }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "StudentProfileCard",
  props: {
    namePosition: "inside" | "outside",
    profileData: Object,
    color: String,
    marginLeft: Number | String,
    scale: Boolean,
  },
  setup(props) {
    const router = useRouter();

    const {
      namePosition = "inside",
      profileData = {},
      color,
      marginLeft = 0,
      scale = false,
    } = props;
    const showMask = ref(false);

    const toStudentPage = () => {
      // const { id = 1 } = profileData;
      const id = props.profileData.work_id;
      router.push(`/portfolios/${id}`);
    };

    return {
      color,
      scale,
      toStudentPage,
      profileData,
      namePosition,
      showMask,
      marginLeft,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-slide-card {
  position: relative;
  width: 100%;

  .image-wrapper {
    width: 100%;
    padding-bottom: 100% * 4 / 3;
    clip-path: polygon(0 0, 100% 0%, 100% 96%, 40% 96%, 40% 100%, 0% 100%);
    overflow: hidden;
    cursor: pointer;

    @include RWD($tablet) {
      clip-path: polygon(0 0, 100% 0%, 100% 96%, 50% 96%, 50% 100%, 0% 100%);
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s;
      background-color: $black;

      &.is-active {
        opacity: 0.3;
      }
    }
  }

  .name-field {
    margin-top: 33px;

    @include RWD($mediumLaptop) {
      margin-top: 20px;
    }

    @include RWD($tablet) {
      margin-top: 5px;
    }

    p {
      margin: 0;
      font-family: "Zpix";
      font-size: 20px;
      line-height: 30px;
      color: $black;
      white-space: nowrap;

      @include RWD($mediumLaptop) {
        font-size: 14px;
        line-height: 24px;
      }

      @include RWD($tablet) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &.scale {
      & > p {
        transform: scale(calc(10 / 12));
        transform-origin: left;
      }
    }

    &.inside {
      margin-top: 0;
      position: absolute;
      bottom: 20px;
      left: 20px;

      @include RWD($tablet) {
        bottom: 10px;
        left: 10px;
      }

      p {
        color: $white;
      }
    }

    &.white {
      p {
        color: $white;
      }
    }
  }
}
</style>
