<template>
  <div ref="sliderElement" class="profile-mobile-slider">
    <section
      class="item-cards"
      @touchstart="startTouch"
      @touchmove="moveTouch"
      :style="{
        transition: '0.5s transform linear',
        transform: `translateX(${-transformXDistance}px)`,
      }"
    >
      <StudentProfileCard
        :style="{
          flex: `0 0 ${cardWidth * 100}%`,
        }"
        v-for="(item, i) in profileList"
        :namePosition="namePosition"
        :key="item.nameInMandarin"
        :profileData="item"
        :color="color"
        :marginLeft="i !== 0 ? margin : 0"
        :scale="scale"
      />
    </section>
    <span
      class="shadows left"
      :class="{
        'white-shadow': shadowColor === 'white',
        hide: startFrom === 'left',
      }"
    />
    <span
      class="shadows right"
      :class="{
        'white-shadow': shadowColor === 'white',
        hide: startFrom === 'right',
      }"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import StudentProfileCard from "@/components/atoms/StudentProfileCard";

export default {
  name: "MobileProfileSwiper",
  components: { StudentProfileCard },
  props: {
    namePosition: "indisde" | "outside",
    profileList: Array,
    oneRowAmount: Number,
    margin: Number,
    cardWidth: Number,
    slidePerTime: Number,
    color: String,
    shadowColor: "black" | "white",
    reverse: Boolean,
    startFrom: "left" | "middle" | "right",
    scale: Boolean,
  },
  setup(props) {
    const {
      profileList = [],
      oneRowAmount = 1,
      slidePerTime = 1,
      namePosition = "indisde",
      margin = 0.04,
      cardWidth = 0.46,
      color,
      startFrom,
      scale = false,
      shadowColor = "black",
    } = props;

    const getStartIndex = (startFrom, listLength) => {
      const lookup = {
        left: 0,
        middle: Math.ceil(listLength / 2) - 1,
        right: listLength - 1,
      };

      return lookup[startFrom];
    };

    const sliderElement = ref(null);
    const slideDistance = ref(0);
    const startIndex = ref(getStartIndex(startFrom, profileList.length));

    onMounted(() => {
      slideDistance.value =
        sliderElement.value.offsetWidth * (cardWidth + margin);

      window.addEventListener("resize", handleResize);
    });

    const transformXDistance = computed(() => {
      if (!sliderElement.value) return 0;

      if (startFrom === "right") {
        const amount = startIndex.value - oneRowAmount;
        const distance = amount * slideDistance.value;
        const per = cardWidth - (1 - (cardWidth + margin) * oneRowAmount);
        const startValue = sliderElement.value.offsetWidth * per + distance;
        return startValue;
      }

      const lookup = {
        left: 0,
        middle: window.innerWidth * (cardWidth / 2),
      };

      const startPoint = lookup[startFrom];

      const slideAmountLookup = {
        left: startIndex.value,
        middle: startIndex.value - 1,
      };
      const slideAmount = slideAmountLookup[startFrom];

      return startPoint + slideAmount * slideDistance.value;
    });

    const handleResize = () => {
      slideDistance.value =
        sliderElement.value.offsetWidth * (cardWidth + margin);
    };

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const toNext = () => {
      if (startFrom === "right" || startFrom === "middle" ) {
        if (profileList.length - startIndex.value === 1) return;
      } else if (startFrom === "left") {
        if (profileList.length - startIndex.value <= 2) return;
      }

      startIndex.value += slidePerTime;
    };

    const toPrevious = () => {
      if (startFrom === "right") {
        if (startIndex.value <= 1) return;
      } else if (startFrom === "left" || startFrom === "middle") {
        if (startIndex.value === 0) return;
      }

      startIndex.value -= slidePerTime;
    };

    const initialX = ref(null);
    const initialY = ref(null);

    const startTouch = (e) => {
      initialX.value = e.touches[0].clientX;
      initialY.value = e.touches[0].clientY;
    };

    const moveTouch = (e) => {
      e.preventDefault();
      if (initialX.value === null) {
        return;
      }

      if (initialY.value === null) {
        return;
      }

      var currentX = e.touches[0].clientX;

      var diffX = initialX.value - currentX;
      if (diffX > 2) {
        // swipe left
        toNext();
      } else {
        toPrevious();
      }

      initialX.value = null;
      initialY.value = null;
    };

    return {
      startTouch,
      moveTouch,
      sliderElement,
      toNext,
      margin,
      oneRowAmount,
      toPrevious,
      profileList,
      transformXDistance,
      namePosition,
      cardWidth,
      color,
      startIndex,
      shadowColor,
      scale,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-mobile-slider {
  display: flex;
  flex-direction: column;

  width: 100%;
  overflow: hidden;

  position: relative;

  .item-cards {
    display: flex;
    flex-wrap: nowrap;

    & > div {
      & + div {
        margin-left: 4%;
      }
    }
  }

  .shadows {
    position: absolute;
    height: 100%;

    &.left {
      left: 0;
      width: 80px;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
      );
    }

    &.right {
      right: 0;
      width: 80px;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
      );
    }

    &.white-shadow {
      &.left {
        background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        );
      }

      &.right {
        background-image: linear-gradient(
          to left,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        );
      }
    }

    &.hide {
      &.left {
        display: none;
      }

      &.right {
        display: none;
      }
    }
  }
}
</style>
